import { useCurrentUser } from '@/auth/useCurrentUser';

import DoctorIcon from '@/icons/user-doctor.svg';

import { IconMenuItem } from './IconMenuItem';

const DoctorAppIconMenuItem = () => {
  const currentUser = useCurrentUser();
  const { doctorId, membershipsCount } = currentUser;

  if (!doctorId && !membershipsCount) return null;

  return (
    <IconMenuItem
      icon={DoctorIcon}
      href={'/doctor/dashboard' as any}
      label="Doctor App"
    />
  );
};

export default DoctorAppIconMenuItem;
