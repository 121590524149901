/* eslint-disable sort-keys */
import { AnimatePresence, motion } from 'framer-motion';
import {
  useEffect,
  useState,
  HTMLProps,
  Dispatch,
  SetStateAction,
  ReactNode,
  RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';

import Text from '@/components/data-display/Text';

import { classNames } from '@/utils/classNames';

type OnExitComplete = [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>,
];

interface BackdropProps {
  children?: ReactNode;
  handleClose?: () => void;
}
interface ModalContentProps {
  ariaLabel?: string;
  children?: ReactNode;
  className?: string;
  handleClose?: () => void;
  spacingCln?: string;
  title?: ReactNode;
}
interface ModalPortalFactoryProps
  extends Omit<HTMLProps<HTMLElement>, 'title'> {
  ariaLabel?: string;
  shouldCloseOnBackdropClick?: boolean;
  hideCloseButton?: boolean;
  isOpen: boolean;
  modalId: string;
  onClose?: () => void;
  onExitComplete?: OnExitComplete;
  title?: ReactNode;
  widthClassName?: string;
}

interface ModalProps extends Omit<ModalPortalFactoryProps, 'modalId'> {
  modalId?: string;
}

const effect = {
  hidden: {
    // y: '-100vh',
    transform: 'scale(0.9)',
    opacity: 0,
    height: '100%',
  },
  visible: {
    // y: '0',
    transform: 'scale(1)',
    height: '100%',
    maxHeight: '98vh',
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
  exit: {
    transform: 'scale(0.9)',
    // y: '100vh',
    height: '100%',
    opacity: 0,
  },
};

const Backdrop = ({ children, handleClose }: BackdropProps) => (
  <motion.div
    className="
      z-50 fixed inset-0
      flex items-center justify-center
      bg-black/40
    "
    onClick={handleClose}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </motion.div>
);

const ModalContent = ({
  ariaLabel,
  children,
  className,
  handleClose,
  spacingCln,
  title,
}: ModalContentProps) => (
  <motion.div
    tabIndex={-1}
    role="dialog"
    aria-modal
    aria-label={ariaLabel}
    className={classNames(
      'relative bg-background flex flex-col max-w-full border border-primary/20',
      className || 'rounded shadow-lg overflow-auto max-h-[90%] scrollbar-hide',
      spacingCln || 'p-5'
    )}
    variants={effect}
    initial="hidden"
    animate="visible"
    exit="exit"
    onClick={event => event.stopPropagation()}
  >
    {(handleClose || title) && (
      <div className="flex items-start justify-between mb-3">
        <div>{title && <Text variant="h5">{title}</Text>}</div>

        {handleClose && (
          <button
            className=""
            onClick={handleClose}
            aria-label={`Close ${ariaLabel || 'dialog'}`}
            type="button"
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
            </svg>
          </button>
        )}
      </div>
    )}
    {/* -2 compensates for the focus ring */}
    <div className="flex flex-col flex-1 overflow-auto p-2 -m-2 scrollbar-hide">
      {children}
    </div>
  </motion.div>
);

const ModalPortalFactory = ({
  ariaLabel,
  shouldCloseOnBackdropClick = true,
  children,
  className,
  hideCloseButton,
  isOpen,
  modalId,
  onClose: handleClose,
  onExitComplete,
  title,
  widthClassName,
}: ModalPortalFactoryProps) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [trigger, setTrigger] = onExitComplete ?? [undefined, undefined];

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  if (!isBrowser) return null;

  const element = document.getElementById(modalId);

  return createPortal(
    <AnimatePresence
      initial={false}
      exitBeforeEnter
      onExitComplete={() =>
        setTrigger && trigger === 'fired' && setTrigger('completed')
      }
    >
      {isOpen && (
        <Backdrop
          handleClose={shouldCloseOnBackdropClick ? handleClose : undefined}
        >
          <FocusOn
            className={widthClassName || 'max-w-full w-[520px]'}
            onClickOutside={
              shouldCloseOnBackdropClick ? handleClose : undefined
            }
            onEscapeKey={handleClose}
            shards={[element as HTMLElement | RefObject<any>]}
          >
            <ModalContent
              className={classNames(
                className,
                'w-11/12 max-w-full mx-auto scrollbar-hide'
              )}
              handleClose={hideCloseButton ? undefined : handleClose}
              ariaLabel={ariaLabel}
              title={title}
            >
              {children}
            </ModalContent>
          </FocusOn>
        </Backdrop>
      )}
    </AnimatePresence>,
    element
  );
};

const Modal = ({ modalId = 'md-prt', ...rest }: ModalProps) => {
  return <ModalPortalFactory modalId={modalId} {...rest} />;
};

export default Modal;
