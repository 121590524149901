import { cloneElement, ReactElement, HTMLAttributes } from 'react';

import { Size } from '@/types/index';

import { classNames } from '@/utils/classNames';
import { renderIcon } from '@/utils/icons';

interface SVGProps extends Partial<HTMLAttributes<HTMLElement>> {
  fill?: string;
  icon: string | ReactElement;
  loading?: boolean;
  size?: Size;
}

export const svgStyles = {
  /* eslint-disable sort-keys */
  size: {
    base: 'h-5 w-5',
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-4 w-4',
    lg: 'h-6 w-6',
    xl: 'h-10 w-10',
    '2xl': 'h-12 w-12',
    '3xl': 'h-14 w-14',
  },
  /* eslint-enable sort-keys */
};

const SVG = ({
  className,
  fill,
  icon: providedIcon,
  loading,
  size,
  ...rest
}: SVGProps) => {
  const isLoading = loading || providedIcon === 'loading';
  const icon = loading || providedIcon === 'loading' ? 'loading' : providedIcon;
  const fillCln = !fill || fill === 'current' ? 'fill-current' : fill;

  const normalizedClassName = classNames(
    isLoading && 'animate-spin',
    svgStyles.size[size || 'base'],
    typeof icon === 'string' ? '' : icon.props.className,
    fillCln,
    className || ''
  );

  if (typeof icon === 'string') {
    const FormattedIcon = renderIcon(icon);

    return <FormattedIcon className={normalizedClassName} {...rest} />;
  }

  const IconClone = cloneElement(icon as ReactElement, {
    ...rest,
    className: normalizedClassName,
  });
  return IconClone;
};

export default SVG;
