import { FieldPolicy, Reference } from '@apollo/client';

type KeyArgs = FieldPolicy<any>['keyArgs'];

export const skipTakePagination = <T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<T[]> => {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      let merged = existing ? existing.slice(0) : [];

      if (args) {
        const { skip = 0 } = args;

        if (skip === 0) {
          merged = [];
        }

        for (let i = 0; i < incoming.length; i += 1) {
          merged[skip + i] = incoming[i];
        }
      } else {
        merged = [...incoming];
      }

      return merged;
    },
  };
};
