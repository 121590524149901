/* eslint-disable import/order */
import AddressBookIcon from '@/icons/address-book.svg';
import AddressCardIcon from '@/icons/address-card.svg';
import AlphaDownIcon from '@/icons/arrow-down-z-a.svg';
import AlphaUpIcon from '@/icons/arrow-up-a-z.svg';
import BoldIcon from '@/icons/bold.svg';
import BriefcaseIcon from '@/icons/briefcase.svg';
import CalendarDaysIcon from '@/icons/calendar-days.svg';
import ChartMixedIcon from '@/icons/chart-mixed.svg';
import CheckIcon from '@/icons/check.svg';
import ChevronDownIcon from '@/icons/chevron-down.svg';
import ChevronLeftIcon from '@/icons/chevron-left.svg';
import ChevronRightIcon from '@/icons/chevron-right.svg';
import ChevronUpIcon from '@/icons/chevron-up.svg';
import CircleCheck from '@/icons/circle-check.svg';
import CircleMinus from '@/icons/circle-minus.svg';
import CircleNotchIcon from '@/icons/circle-notch.svg';
import ClockIcon from '@/icons/clock.svg';
import CloseIcon from '@/icons/times.svg';
import EditIcon from '@/icons/edit.svg';
import EllipsisIcon from '@/icons/ellipsis.svg';
import EllipsisStrokeIcon from '@/icons/ellipsis-stroke.svg';
import EmailIcon from '@/icons/envelope.svg';
import EyeIcon from '@/icons/eye.svg';
import FacebookIcon from '@/icons/facebook-f.svg';
import FaxIcon from '@/icons/fax.svg';
import FileContractIcon from '@/icons/file-contract.svg';
import FileMedicalIcon from '@/icons/file-medical.svg';
import GridDividersIcon from '@/icons/grid-dividers.svg';
import GoogleIcon from '@/icons/google.svg';
import H1Icon from '@/icons/h1.svg';
import H2Icon from '@/icons/h2.svg';
import H3Icon from '@/icons/h3.svg';
import H4Icon from '@/icons/h4.svg';
import HistoryIcon from '@/icons/history.svg';
import HourglassEndIcon from '@/icons/hourglass-end.svg';
import HouseBlankIcon from '@/icons/house-blank.svg';
import HouseChimneyMedicalIcon from '@/icons/house-chimney-medical.svg';
import InfoIcon from '@/icons/info.svg';
import InfoCircleIcon from '@/icons/circle-info.svg';
import InstagramIcon from '@/icons/instagram.svg';
import InvoiceDollarIcon from '@/icons/file-invoice-dollar.svg';
import ItalicIcon from '@/icons/italic.svg';
import LinkedInIcon from '@/icons/linkedin-in.svg';
import LinkIcon from '@/icons/link.svg';
import ListCheckIcon from '@/icons/list-check.svg';
import MessagesIcon from '@/icons/messages.svg';
import MicrophoneIcon from '@/icons/microphone.svg';
import MicrophoneOffIcon from '@/icons/microphone-slash.svg';
import MoneyBill1Icon from '@/icons/money-bill-1.svg';
import NotDefIcon from '@/icons/notdef.svg';
import NumberHighestFirstIcon from '@/icons/arrow-up-9-1.svg';
import NumberLowestFirstIcon from '@/icons/arrow-up-1-9.svg';
import OrderedListIcon from '@/icons/list-ol.svg';
import PaperPlaneIcon from '@/icons/paper-plane.svg';
import ParagraphIcon from '@/icons/paragraph.svg';
import PhoneHangUpIcon from '@/icons/phone-hangup.svg';
import PhoneIcon from '@/icons/phone.svg';
import PlusIcon from '@/icons/plus.svg';
import PreviewPdfIcon from '@/icons/file-pdf-solid.svg';
import QuoteIcon from '@/icons/quote-left.svg';
import ReplyIcon from '@/icons/reply.svg';
import SaveIcon from '@/icons/save.svg';
import ShareIcon from '@/icons/share.svg';
import SignOut from '@/icons/sign-out.svg';
import TimerIcon from '@/icons/timer.svg';
import TrashIcon from '@/icons/trash.svg';
import TwitterIcon from '@/icons/twitter.svg';
import UnderlineIcon from '@/icons/underline.svg';
import UnLinkIcon from '@/icons/link-slash.svg';
import UnorderedListIcon from '@/icons/list-ul.svg';
import UploadIcon from '@/icons/upload-solid.svg';
import UserIcon from '@/icons/user.svg';
import UserDoctorIcon from '@/icons/user-doctor.svg';
import UserNurseIcon from '@/icons/user-nurse.svg';
import VideoCameraIcon from '@/icons/video.svg';
import VideoCameraOffIcon from '@/icons/video-slash.svg';
import YoutubeIcon from '@/icons/youtube.svg';

export const renderIcon = icon => {
  switch (icon) {
    case 'address-book':
      return AddressBookIcon;
    case 'address-card':
      return AddressCardIcon;
    case 'bold':
      return BoldIcon;
    case 'briefcase':
      return BriefcaseIcon;
    case 'calendar-days':
      return CalendarDaysIcon;
    case 'clock':
      return ClockIcon;
    case 'circle-check':
      return CircleCheck;
    case 'circle-minus':
      return CircleMinus;
    case 'chart-mixed':
      return ChartMixedIcon;
    case 'email':
      return EmailIcon;
    case 'ellipsis-stroke':
      return EllipsisStrokeIcon;
    case 'file-contract':
      return FileContractIcon;
    case 'contract':
      return FileContractIcon;
    case 'file-medical':
      return FileMedicalIcon;
    case 'h1':
      return H1Icon;
    case 'h2':
      return H2Icon;
    case 'h3':
      return H3Icon;
    case 'h4':
      return H4Icon;
    case 'invoice-dollar':
      return InvoiceDollarIcon;
    case 'info':
      return InfoIcon;
    case 'info-circle':
      return InfoCircleIcon;
    case 'italic':
      return ItalicIcon;
    case 'list-check':
      return ListCheckIcon;
    case 'list-ol':
      return OrderedListIcon;
    case 'link':
      return LinkIcon;
    case 'messages':
      return MessagesIcon;
    case 'microphone':
      return MicrophoneIcon;
    case 'microphone-off':
      return MicrophoneOffIcon;
    case 'money-bill-1':
      return MoneyBill1Icon;
    case 'not-def':
      return NotDefIcon;
    case 'paragraph':
      return ParagraphIcon;
    case 'phone':
      return PhoneIcon;
    case 'phone-hang-up':
      return PhoneHangUpIcon;
    case 'quote':
      return QuoteIcon;
    case 'timer':
      return TimerIcon;
    case 'video-camera':
      return VideoCameraIcon;
    case 'video-camera-off':
      return VideoCameraOffIcon;
    case 'underline':
      return UnderlineIcon;
    case 'un-link':
      return UnLinkIcon;
    case 'list-ul':
      return UnorderedListIcon;
    case 'user':
      return UserIcon;

    case 'alph2aDown':
      return AlphaDownIcon;
    case 'alphaUp':
      return AlphaUpIcon;
    case 'add':
      return PlusIcon;
    case 'close':
      return CloseIcon;
    case 'cancel':
      return CloseIcon;
    case 'create':
      return PlusIcon;
    case 'check':
      return CheckIcon;
    case 'delete':
      return TrashIcon;
    case 'down':
      return ChevronDownIcon;
    case 'dots':
      return EllipsisIcon;
    case 'edit':
      return EditIcon;
    case 'fb':
      return FacebookIcon;
    case 'fax':
      return FaxIcon;
    case 'facebook':
      return FacebookIcon;
    case 'google':
      return GoogleIcon;
    case 'user-doctor':
      return UserDoctorIcon;
    case 'grid-dividers':
      return GridDividersIcon;
    case 'history':
      return HistoryIcon;
    case 'hourglass-end':
      return HourglassEndIcon;
    case 'house-blank':
      return HouseBlankIcon;
    case 'house-chimney-medical':
      return HouseChimneyMedicalIcon;
    case 'instagram':
      return InstagramIcon;
    case 'left':
      return ChevronLeftIcon;
    case 'linkedin':
      return LinkedInIcon;
    case 'loading':
      return CircleNotchIcon;
    case 'numberAsc':
      return NumberLowestFirstIcon;
    case 'numberDesc':
      return NumberHighestFirstIcon;
    case 'ok':
      return CheckIcon;
    case 'paper-plane':
      return PaperPlaneIcon;
    case 'preview':
      return EyeIcon;
    case 'preview-pdf':
      return PreviewPdfIcon;
    case 'reply':
      return ReplyIcon;
    case 'right':
      return ChevronRightIcon;
    case 'save':
      return SaveIcon;
    case 'sign-out':
      return SignOut;
    case 'share':
      return ShareIcon;
    case 'trash':
      return TrashIcon;
    case 'twitter':
      return TwitterIcon;
    case 'user-nurse':
      return UserNurseIcon;
    case 'upload':
      return UploadIcon;
    case 'up':
      return ChevronUpIcon;
    case 'youtube':
      return YoutubeIcon;
    case 'yt':
      return YoutubeIcon;
    case 'view':
      return EyeIcon;

    default:
      return icon;
  }
};
