import TextareaAutosize from 'react-textarea-autosize';

import { classNames } from '@/utils/classNames';

import { inputClassNames } from './FullInput';
import { InputLabel } from './InputLabel';

export const FullTextArea = (props: any) => {
  const {
    block,
    className: providedClassName,
    colorScheme = 'default',
    containerClassName,
    dataTestId,
    defaultValue,
    description,
    disabled = false,
    error,
    id,
    invalid: providedInvalid = false,
    label,
    labelExtra,
    labelTooltipContent,
    labelTooltipOptions,
    labelTooltipTrigger,
    maxRows,
    methods,
    minRows = 3,
    name,
    onChange,
    orientation,
    placeholder,
    required,
    size = 'base',
    textAreaWrapperClassName,
    validate,
    variant = 'outline',
    width = '100%',
    ...rest
  } = props;

  const errorMessage = methods ? methods.errors[name]?.message : error;
  const invalid = providedInvalid || !!errorMessage;
  const descriptionText = errorMessage || description;

  const coreProps = inputClassNames.core;
  const transitionProps = inputClassNames.transition;

  const shadowStyles = inputClassNames[variant]?.shadow;
  const inputSpacingStyles = inputClassNames[size].spacing;
  const inputHeightStyles = inputClassNames[size].height;

  const ringStyles = inputClassNames.ring;
  const normalizedColorScheme = disabled
    ? 'inactive'
    : invalid
    ? 'invalid'
    : colorScheme;

  const bgColorStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.bgColor;
  const borderStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.border;
  const valueStyles = inputClassNames[variant]?.[normalizedColorScheme]?.value;

  const descriptionStyles =
    inputClassNames[variant]?.[normalizedColorScheme]?.description;

  const layoutClassNames = classNames(
    containerClassName,
    block && 'flex-1 w-full max-h-full flex flex-col',
    orientation === 'horizontal' && 'flex gap-2 items-center'
  );

  const withRequiredValidation = required
    ? { required: 'This field is required.' }
    : {};
  const fieldValidation = { ...withRequiredValidation, ...validate };

  const inputControlProps = methods
    ? {
        ...methods.register(name, fieldValidation),
        ...rest,
      }
    : {
        ...rest,
      };

  const inputUUID =
    typeof window !== 'undefined' ? window.crypto.randomUUID() : undefined;
  const inputId = id || name || inputUUID;

  return (
    <div className={layoutClassNames}>
      <InputLabel
        colorScheme={normalizedColorScheme}
        content={label}
        extraContent={labelExtra}
        inputId={inputId}
        tooltipContent={labelTooltipContent}
        tooltipOptions={labelTooltipOptions}
        tooltipTrigger={labelTooltipTrigger}
        variant={variant}
      />

      <div
        className={classNames(
          'relative',
          textAreaWrapperClassName,
          transitionProps,
          orientation !== 'horizontal' && ' mt-0'
        )}
      >
        <TextareaAutosize
          aria-invalid={invalid ? 'true' : undefined}
          className={classNames(
            shadowStyles,
            bgColorStyles,
            borderStyles,
            ringStyles,
            coreProps,
            inputHeightStyles,
            inputSpacingStyles,
            valueStyles,
            transitionProps,
            providedClassName
          )}
          data-testid={dataTestId}
          defaultValue={defaultValue}
          invalid={invalid ? 'true' : null}
          disabled={disabled}
          maxRows={maxRows}
          minRows={minRows}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          rows={minRows}
          width={width}
          {...inputControlProps}
          id={inputId}
        />
      </div>

      {descriptionText && (
        <p
          data-testid="feedback-textarea"
          className={classNames(descriptionStyles, '!-mt-1')}
        >
          {descriptionText}
        </p>
      )}
    </div>
  );
};
