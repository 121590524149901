/* eslint-disable sort-keys */
import { HTMLAttributes, ElementType } from 'react';

import { classNames } from '@/utils/classNames';

export const textColorClassNames = {
  default: 'text-text',
  current: 'text-text',
  faded: 'text-inherit/50',
  inherit: 'text-text',
};

export const textVariantClassNames = {
  caption: {
    family: 'font-sans',
    weight: 'font-medium',
    size: 'text-sm',
  },
  h1: {
    family: 'font-serif',
    weight: 'font-bold',
    size: 'text-4xl',
  },
  h2: {
    family: 'font-serif',
    weight: 'font-bold',
    size: 'text-3xl',
  },
  h3: {
    family: 'font-serif',
    weight: 'font-bold',
    size: 'text-2xl',
  },
  h4: {
    family: 'font-serif',
    weight: 'font-semibold',
    size: 'text-xl',
  },
  h5: {
    family: 'font-serif',
    weight: 'font-semibold',
    size: 'text-base',
  },
  h6: {
    family: 'font-serif',
    weight: 'font-semibold',
    size: 'text-sm',
  },
};

export type TextColor = keyof typeof textColorClassNames;
export type TextVariant = keyof typeof textVariantClassNames;

interface TextProps extends HTMLAttributes<HTMLElement> {
  align?: 'left' | 'center' | 'right';
  as?: ElementType | string;
  bold?: boolean;
  block?: boolean;
  center?: boolean;
  color?: TextColor;
  colorScheme?: TextColor;
  dataTestId?: string;
  htmlFor?: string;
  variant?: TextVariant;
  family?: string;
}

const Text = ({
  align,
  as: Component = 'div',
  block,
  bold,
  center,
  children,
  className,
  dataTestId,
  variant,
  color,
  colorScheme,
  ...props
}: TextProps) => {
  const fontColors = `${
    textColorClassNames[(color as TextColor) || colorScheme] || ''
  }`;

  const fontSize = `${
    textVariantClassNames[variant as TextVariant]?.size || ''
  }`;

  const fontFamily = `${
    textVariantClassNames[variant as TextVariant]?.family || ''
  }`;
  const fontWeight = bold
    ? 'font-bold'
    : `${textVariantClassNames[variant as TextVariant]?.weight || ''}`;

  const alignClassName = align === 'center' ? 'center block' : '';
  const displayClassName = block ? 'block' : '';

  const textClassName = classNames(
    alignClassName,
    displayClassName,
    fontColors,
    fontSize,
    fontFamily,
    fontWeight,
    center ? 'text-center' : '',
    className
  );

  return (
    <Component {...props} className={textClassName} data-testid={dataTestId}>
      {children}
    </Component>
  );
};

export default Text;
