import moment from 'moment';
import PasswordValidator from 'password-validator';
import isEmail from 'validator/lib/isEmail';

const schema = new PasswordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(48) // Maximum length 100
  .has()
  .letters() // Must have uppercase letters
  .has()
  .digits(); // Must have digits.

export const requiredCheckbox = value =>
  value !== undefined &&
  value !== null &&
  value !== '' &&
  value !== false &&
  !(Array.isArray(value) && value.length === 0)
    ? undefined
    : 'Required';

export const email = value =>
  value && !isEmail(value) ? `Email appears to be invalid` : undefined;

export const required = value => {
  if (typeof value === 'undefined' || value === null || value === '')
    return 'Required';
  return undefined;
};

export const maxLength = max => value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return value.length > max ? `Must be ${max} characters or less` : undefined;
};

export const exactLength = length => value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return value.toString().length !== length
    ? `Must be exactly ${length} characters`
    : undefined;
};

export const maxLength15 = maxLength(15);

export const number = value => {
  if (typeof value === 'undefined' || value === null) return undefined;

  return isNaN(parseInt(value, 10)) ? 'Must be a number' : undefined;
};

export const phoneNumber = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  const regExp = /^\+1[2-9]([0-9]{9})$/;
  return !regExp.test(value) ? 'Phone number is not valid' : undefined;
};

export const min = (minValue, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return minValue > testValue
    ? `Should be equal or more than ${
        formatter && typeof formatter === 'function'
          ? formatter(minValue)
          : minValue
      }`
    : undefined;
};

export const minAge = (minValue, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return minValue > testValue
    ? `Must be ${
        formatter && typeof formatter === 'function'
          ? formatter(minValue)
          : minValue
      } or older`
    : undefined;
};

export const maxInvestmentAnnuity = () => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return testValue > 1000000
    ? `Online quotes are limited to a $1 million investment. For investments over $1 million, please contact us directly so we can help you get the best quote`
    : undefined;
};

export const max = (maxValue, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;

  return maxValue < testValue
    ? `Should be equal or less than ${
        formatter && typeof formatter === 'function'
          ? formatter(maxValue)
          : maxValue
      }`
    : undefined;
};

// error if testDate is inside a particularly bad range
export const shortDateOutsideOfRange =
  (minLimit, maxLimit, message) => testDate => {
    if (typeof testDate === 'undefined' || testDate === null) return undefined;
    return testDate < maxLimit && testDate > minLimit ? message : undefined;
  };

export const maxAgeLife = (maxAge, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return maxAge < testValue
    ? `Instant term life quotes only available up to age ${
        formatter && typeof formatter === 'function'
          ? formatter(maxAge)
          : maxAge
      }. If you are over ${
        formatter && typeof formatter === 'function'
          ? formatter(maxAge)
          : maxAge
      }, please message us on chat and we’ll help find your best rates.`
    : undefined;
};

export const maxAgeLTC = (maxAge, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return maxAge < testValue
    ? `Long-term care insurance is generally only available to those age ${
        formatter && typeof formatter === 'function'
          ? formatter(maxAge)
          : maxAge
      } and younger`
    : undefined;
};

export const maxAgeDisability = (maxAge, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return moment(testValue).isBefore(moment().subtract(maxAge, 'y'))
    ? `Disability insurance is generally only available to those age ${
        formatter && typeof formatter === 'function'
          ? formatter(maxAge)
          : maxAge
      } and younger`
    : undefined;
};

export const minAgeAnnuity = (minimumAge, formatter) => testValue => {
  if (typeof testValue === 'undefined' || testValue === null) return undefined;
  return moment(testValue).isAfter(moment().subtract(minimumAge, 'y'))
    ? `Income annuities are generally only available to those age ${
        formatter && typeof formatter === 'function'
          ? formatter(minimumAge)
          : minimumAge
      } and older`
    : undefined;
};

export const height =
  (minimumHeight = 35, maxHeight = 108) =>
  testHeight => {
    if (typeof testHeight === 'undefined' || testHeight === null)
      return undefined;

    return (testHeight && testHeight < minimumHeight) ||
      (testHeight && testHeight > maxHeight)
      ? `Please double check your entry or contact support.`
      : undefined;
  };

export const birthday =
  (minimumAge = 18, maximumAge = 120) =>
  testDate => {
    if (typeof testDate === 'undefined' || testDate === null) return undefined;

    if (moment(testDate).isAfter(moment().subtract(minimumAge, 'y'))) {
      return `You must be at least ${minimumAge} years old.`;
    }
    if (moment().subtract(maximumAge, 'y').isAfter(moment(testDate))) {
      return 'Please double check your birthday or contact support.';
    }

    return undefined;
  };

// @@@@ iJS Validations

export const richTextHasLength = value => {
  const requiredMessage = 'This field is required';

  if (typeof value === 'undefined' || value === null) return requiredMessage;

  if (typeof value === 'string') {
    const parsedToJSON = JSON.parse(value);
    const firstChild = parsedToJSON[0].children[0].text;

    return firstChild.length < 1 ? requiredMessage : undefined;
  }

  if (value[0]?.children[0]?.text?.length < 1) return requiredMessage;

  return undefined;
};

export const invalidVersionCode = value => {
  if (!value) return undefined;
  const regex = /^[A-Z]{2}$/;
  return !regex.test(value) ? 'Version code must be 2 letters' : undefined;
};

export const invalidFutureDate = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return moment(value).isBefore(moment())
    ? 'Date must be in the future'
    : undefined;
};

export const invalidPastDate = value => {
  if (typeof value === 'undefined' || value === null) return undefined;
  return moment(value).isAfter(moment())
    ? 'Date must be in the past'
    : undefined;
};

export const ohipBillingCodeGroup = value => {
  if (!value) return undefined;
  const regex = /^\d{4}$/;
  return !regex.test(value) ? 'Group code must be 4 digits' : undefined;
};

export const ohipBillingCodePractitionerNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{5,6}$/;
  return !regex.test(value)
    ? 'Practitioner number must be 5 or 6 digits'
    : undefined;
};

export const ohipBillingCodeSpecialty = value => {
  if (!value) return undefined;
  const regex = /^\d{2}$/;
  return !regex.test(value) ? 'Specialty code must be 2 digits' : undefined;
};

export const luCodeNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{3}$/;
  return !regex.test(value) ? 'LU code must be 3 digits' : undefined;
};

export const medicalLiabilityInsuranceNumber = value => {
  if (!value) return undefined;
  const regex = /^\d{9}$/;
  return !regex.test(value)
    ? 'Medical Liability Insurance Number must be 9 digits'
    : undefined;
};

// validator for blood pressure (mmHg)
export const bloodPressure = value => {
  if (!value) return undefined;
  const regex = /^\d{2,3}\/\d{2,3}$/;
  return !regex.test(value)
    ? 'Blood pressure must be in format 120/80'
    : undefined;
};
