import { errorToast } from '@/components/feedback/utils/doNotify';

import { useCurrentUserQuery, AuthenticatedItem } from '@/generated/graphql';

import { signOut } from '@/lib/auth';

export interface CurrentUser extends Partial<AuthenticatedItem> {
  doctorId?: string;
  fullName?: string;
  isLoggedIn?: boolean;
  loading?: boolean;
  patientId?: string;
  signOut?: typeof signOut;
  stripeCusId?: string;
  userId?: string;
}

export const useCurrentUser = (): CurrentUser => {
  const { data, loading, error } = useCurrentUserQuery({});

  if (error) errorToast(error);

  if (loading) return { loading: true };
  if (!data?.authenticatedItem) return { loading: false };

  const currentUser = data?.authenticatedItem;

  const { firstName, lastName } = currentUser;
  const userId = currentUser.id;
  const doctorId = currentUser.doctor?.id;
  const patientId = currentUser.patient?.id;

  const isLoggedIn = true;

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

  return {
    ...currentUser,
    doctorId,
    fullName,
    isLoggedIn,
    patientId,
    signOut,
    stripeCusId: currentUser.stripeCusId as string,
    userId,
  };
};
